import "./reviews.scss";

import * as dompack from "dompack";
import getTid from "@mod-tollium/js/gettid";

let allShown, reviews, currentLang, toggleBtn;

dompack.onDomReady(() => {
  if (!dompack.qS(".w-reviews__container")) return;

  allShown = false;
  reviews = dompack.qSA(".w-reviews__review");
  currentLang = document.querySelector("html").getAttribute("lang");

  toggleBtn = dompack.qS("#btn-reviews-toggler");
  if (toggleBtn) {
    toggleBtn.onclick = toggleLanguages;
    toggleLanguages();
  }

  setupExpandableReviews();
});

function toggleLanguages(evt) {
  if (evt) dompack.stop(evt);

  allShown = !allShown;

  const newText = allShown
    ? toggleBtn.dataset.hideText
    : toggleBtn.dataset.showText;

  toggleBtn.textContent = newText;

  reviews.forEach((review) => {
    const showReview = allShown || currentLang === review.dataset.language;

    dompack.toggleClass(review, "hidden", !showReview);
  });
}

function setupExpandableReviews() {
  const container = dompack.qS(".w-reviews__container");
  const buttons = dompack.qSA(".w-reviews__readmore");

  const updateButtonVisibility = (button, content) => {
    if (content.scrollHeight <= content.clientHeight) {
      button.dataset.show = "false";
      button.style.display = "none"; // Hide the readmore button
    } else {
      button.dataset.show = "true";
      button.style.display = "block"; // Show the readmore button
    }
  };

  const debounce = (func, wait) => {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  };

  const debouncedUpdate = debounce(() => {
    buttons.forEach((button) => {
      const parent = button.closest(".w-reviews__review");
      const content = parent.querySelector(".w-reviews__content");
      updateButtonVisibility(button, content);
    });
  }, 100);

  // Initial check
  buttons.forEach((button) => {
    const parent = button.closest(".w-reviews__review");
    const content = parent.querySelector(".w-reviews__content");
    updateButtonVisibility(button, content);

    // Set initial state
    let isExpanded = false;

    // Set the maximum height (collapsed state)
    const lineHeight = parseFloat(window.getComputedStyle(content).lineHeight);
    const maxLines = 3;
    const maxHeight = lineHeight * maxLines;

    // Apply the initial max height
    content.style.height = `${maxHeight}px`;

    // Click event listener
    button.addEventListener("click", function (evt) {
      dompack.stop(evt);

      if (isExpanded) {
        // Collapse content
        content.style.height = `${maxHeight}px`;
        content.classList.remove("expand");
        button.textContent = getTid("nubiumreviews:js.showmore");
        content.scrollIntoView({ behavior: "smooth", block: "nearest" });
      } else {
        // Expand content
        const fullHeight = content.scrollHeight;
        content.style.height = `${fullHeight}px`;
        content.classList.add("expand");
        button.textContent = getTid("nubiumreviews:js.showless");
      }

      isExpanded = !isExpanded;
    });
  });

  // Add debounced resize event listener
  window.addEventListener("resize", debouncedUpdate);
}
