/* global Swiper */
import './slideshow.scss';

import * as dompack from 'dompack';

// Manages parallax effect for slideshow backgrounds
const ParallaxManager = {
  activeElements: new Map(),
  ticking: false,
  viewportMiddle: window.innerHeight / 2,
  lastScrollY: window.pageYOffset,
  enabled: false,
  rafId: null,
  resizeTimeout: null,

  init() {
    if (!document.querySelector('.w-slideshow.hasparallax')) return;

    this.enabled =
      !('ontouchstart' in window) &&
      matchMedia(
        '(min-width: 768px) and (prefers-reduced-motion: no-preference)'
      ).matches;

    if (!this.enabled) return;

    this._boundScroll = this.onScroll.bind(this);
    this._boundUpdate = this.updateElements.bind(this);

    this.updateViewportMiddle();

    window.addEventListener('scroll', this._boundScroll, { passive: true });
    window.addEventListener('resize', this.onResize.bind(this), {
      passive: true,
    });
  },

  updateViewportMiddle() {
    this.viewportMiddle = window.innerHeight * 0.5;
  },

  onResize() {
    if (this.resizeTimeout) return;

    this.resizeTimeout = setTimeout(() => {
      this.updateViewportMiddle();
      this.resizeTimeout = null;
      if (this.activeElements.size) {
        this.updateElements();
      }
    }, 100);
  },

  onScroll() {
    this.lastScrollY = window.pageYOffset;

    if (!this.ticking && this.activeElements.size) {
      this.ticking = true;
      this.rafId = requestAnimationFrame(this._boundUpdate);
    }
  },

  initSwiper(swiper) {
    const updateActiveSlide = () => {
      const prevBgImage = this.activeElements.get(swiper);
      if (prevBgImage) {
        prevBgImage.style.transform = 'none';
        this.activeElements.delete(swiper);
      }

      const bgImage = swiper.slides[swiper.activeIndex]?.querySelector(
        '.w-slideshow__bgimage'
      );
      if (bgImage) {
        this.activeElements.set(swiper, bgImage);
        if (!this.ticking) {
          this.rafId = requestAnimationFrame(this._boundUpdate);
          this.ticking = true;
        }
      }
    };

    updateActiveSlide();

    swiper.on('slideChange', updateActiveSlide);
  },

  updateElements() {
    if (!this.activeElements.size) {
      this.ticking = false;
      return;
    }

    const multiplier = 0.3;
    for (const element of this.activeElements.values()) {
      const rect = element.getBoundingClientRect();
      const elementMiddle = rect.top + rect.height * 0.5;
      const offset = -((elementMiddle - this.viewportMiddle) * multiplier);
      element.style.transform = `translateY(${offset}px)`;
    }

    this.ticking = false;
  },

  destroy() {
    if (this.rafId) {
      cancelAnimationFrame(this.rafId);
    }
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    window.removeEventListener('scroll', this._boundScroll);

    for (const element of this.activeElements.values()) {
      element.style.transform = 'none';
    }
    this.activeElements.clear();
  },

  initStatic(element) {
    const bgImage = element.querySelector('.w-slideshow__bgimage');
    if (bgImage) {
      this.activeElements.set(element, bgImage);
      if (!this.ticking) {
        this.rafId = requestAnimationFrame(this._boundUpdate);
        this.ticking = true;
      }
    }
  },
};

// Initialize ParallaxManager when DOM is ready
if (document.readyState !== 'loading') {
  ParallaxManager.init();
} else {
  document.addEventListener('DOMContentLoaded', () => ParallaxManager.init());
}

dompack.onDomReady(() => {
  // Exit if no slideshows found
  if (dompack.qSA('.w-slideshow').length === 0) return;

  for (const elSlideshow of dompack.qSA('.w-slideshow')) {
    // Setup parallax effect if enabled
    const hasParallax = elSlideshow.classList.contains('hasparallax');
    if (hasParallax) {
      elSlideshow
        .querySelectorAll('.w-slideshow__bgimage')
        .forEach((img) => img.classList.add('parallax'));
    }

    const elContainer = elSlideshow.querySelector('.swiper-container');

    // Handle static slideshow with parallax
    if (!elContainer) {
      if (hasParallax && ParallaxManager.enabled) {
        ParallaxManager.initStatic(elSlideshow);
      }
      continue;
    }

    // Setup Swiper instance
    const numSlides = elContainer.querySelectorAll('.swiper-slide').length;
    if (numSlides < 1) continue;

    // UI classes for slide count
    if (numSlides === 1) {
      elContainer.classList.add('w-slideshow--single-slide');
    } else {
      elSlideshow.classList.add('w-slideshow--has-slides');
    }

    // Configure Swiper behavior
    const slidesPerView = elSlideshow.dataset.slidesperview ?? 1;
    const settings = {
      direction: 'horizontal',
      loop: slidesPerView !== 'auto',
      slidesPerView,
      speed: 600,
      grabCursor: numSlides > 1,
      allowSlidePrev: numSlides > 1,
      allowSlideNext: numSlides > 1,
      watchOverflow: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    };

    // Enable autoplay if speed is specified
    const autoplaySpeed = parseInt(elSlideshow.dataset.autoplayspeed, 10);
    if (autoplaySpeed > 0) {
      settings.autoplay = {
        delay: autoplaySpeed,
        disableOnInteraction: false,
      };
    }

    // Initialize Swiper and parallax
    const swiper = new Swiper(elContainer, settings);
    if (hasParallax && ParallaxManager.enabled) {
      ParallaxManager.initSwiper(swiper);
    }
  }
});
