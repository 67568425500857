/* global AccessibleMenu, Mmenu, $ */

import * as dompack from 'dompack';
import * as whintegration from '@mod-system/js/wh/integration';
import MobileMenu from '@mod-ww/components/mobilemenu/mobilemenu';

dompack.onDomReady(() => {
  if (whintegration.config.site.module_mmenu === true) {
    initializeMMenu();
  } else {
    new MobileMenu();
    for (const el of dompack.qSA('.ww-mobilemenu')) {
      el.classList.add('init');
    }
  }

  if (typeof AccessibleMenu !== 'undefined') {
    initializeAccessibleMenu();
  }

  const header = document.querySelectorAll('header.ww-header')[0];

  // calc minwidth of header
  const minMenuWidth = () => {
    let sum = 0;

    sum +=
      //header padding left
      parseInt(window.getComputedStyle(header.children[0]).getPropertyValue('padding-left')) +
      //header padding right
      parseInt(window.getComputedStyle(header.children[0]).getPropertyValue('padding-right')) +
      //logo width
      document.querySelector('.ww-header__logolinkcontainer').scrollWidth;

    //all menu items
    document.querySelectorAll('.ww-header__desktopmenu > .ww-menu > .ww-menu-item').forEach((el) => {
      sum +=
        el.offsetWidth +
        parseInt(window.getComputedStyle(el).getPropertyValue('margin-left')) +
        parseInt(window.getComputedStyle(el).getPropertyValue('margin-right'));
    });

    //added menu buttons
    document.querySelectorAll('.ww-header__desktopmenu > .ww-menu-item').forEach((el) => {
      sum +=
        el.offsetWidth +
        parseInt(window.getComputedStyle(el).getPropertyValue('margin-left')) +
        parseInt(window.getComputedStyle(el).getPropertyValue('margin-right'));
    });

    return sum;
  };

  // set min value for minwidth
  const minWidth = Math.max(1024, minMenuWidth());

  // get mobile menu and desktop menu
  const mm = document.querySelector('#toggle-mobile-menu');
  const dm = document.querySelectorAll('.ww-header__desktopmenu, .ww-menu');

  let mmToggled = header.clientWidth < minWidth ? true : false;

  // setup menu display
  if (mm) mm.style.display = mmToggled ? 'block' : 'none';
  else console.log('mobile menu is disabled');
  if (dm) dm.forEach((el) => (el.style.display = mmToggled ? 'none' : 'flex'));
  else console.log('desktop menu is disabled');

  // toggles the mobile menu
  const toggleMobileMenu = () => {
    mmToggled = !mmToggled;
    if (mm) mm.style.display = mmToggled ? 'block' : 'none';
    if (dm) dm.forEach((el) => (el.style.display = mmToggled ? 'none' : 'flex'));
  };

  let ticking = false;
  let rafId;
  let lastWidth = header.clientWidth;

  const updateMenu = () => {
    const width = header.clientWidth;

    if (!mmToggled && width < minWidth) {
      toggleMobileMenu();
    }
    if (mmToggled && width >= minWidth) {
      toggleMobileMenu();
    }

    lastWidth = width;
    ticking = false;
  };

  const onResize = () => {
    if (!ticking) {
      ticking = true;
      rafId = requestAnimationFrame(updateMenu);
    }
  };

  // Use ResizeObserver for more reliable resize detection
  const resizeObserver = new ResizeObserver(onResize);

  // Observe the header element
  resizeObserver.observe(header);
});

function initializeAccessibleMenu() {
  const navs = document.querySelectorAll('nav');
  const menuSettings = {
    menuItemSelector: '.ww-menu-item',
    submenuItemSelector: '.ww-menu-item.ww-dropdown',
    submenuToggleSelector: '.ww-dropdown-toggle',
    submenuSelector: '.ww-menu.ww-dropdown',
  };

  //init language dropdown
  const langMenu = dompack.qS('.ww-languagemenu');
  if (langMenu) {
    new AccessibleMenu.DisclosureMenu({
      menuElement: langMenu,
      ...menuSettings,
      isHoverable: true,
    });
  }

  navs.forEach((nav) => {
    const menuElements = nav.querySelectorAll('.ww-menu');

    menuElements.forEach((menuElement) => {
      if (nav.id === 'ww-main-menu') {
        const controllerElement = nav.querySelector('.ww-menu-toggle');
        new AccessibleMenu.DisclosureMenu({
          menuElement,
          ...menuSettings,
          controllerElement,
          containerElement: nav,
          isHoverable: true,
        });
      } else {
        const menu = new AccessibleMenu.DisclosureMenu({
          menuElement,
          ...menuSettings,
        });
      }
    });
  });

  if (dompack.debugflags['debugmenu']) {
    for (const elDropdown of dompack.qSA('.ww-dropdown')) {
      elDropdown.classList.add('show');
    }
  }
}

function initializeMMenu() {
  if (!dompack.qS('#my-menu')) {
    return;
  }

  var $menu = new Mmenu('#my-menu', {
    navbar: {
      title: whintegration.config.site.sitetitle || ' ',
    },
    slidingSubmenus: false,
    extensions: ['pagedim-black', 'position-right'],
    counters: true,
  });

  if (whintegration.config.site.hassearchpage === true) {
    $('.mm-search').on('click', '.fa-search', function () {
      location.href = whintegration.config.site.searchpagelink + '?words=' + $('#mobile-search').val();
    });

    if ($('.search__words') !== undefined) {
      $('#mobile-search').val($('.search__words').text());
    }
  }

  // set menu to visible after init (prevents showing onload)
  const elMyMenu = dompack.qS('#my-menu');
  elMyMenu.style.opacity = 1;
  elMyMenu.style.pointerEvents = 'auto';
  elMyMenu.style.height = 'auto';

  const $toggleMmenu = $('.js-ww-toggle-mobile-menu');
  $toggleMmenu.click(function (evt) {
    evt.preventDefault();
    $menu.API.open();
  });

  if (!$menu.API) {
    console.error('mmenu not initialized');
    return;
  }

  $menu.API.bind('open:start', function () {
    setTimeout(function () {
      $toggleMmenu.addClass('is-active');
    }, 100);
  });

  $menu.API.bind('close:start', function () {
    setTimeout(function () {
      $toggleMmenu.removeClass('is-active');
    }, 100);
  });
}
